<!--模板-->
<template>
  <div class="">
    <div class="right-top">
      <div class="search">
        <div style="margin-right: 36px">
          <span style="margin-right: 5px">检查内容:</span>
          <a-select placeholder="请选择" style="width: 272px" @change="handleChange">
            <a-select-option value="jack"> Jack </a-select-option>
          </a-select>
        </div>
        <a-button type="primary" style="margin-right: 5px">查询</a-button>
        <a-button>重置</a-button>
      </div>
      <div class="newBuild">
        <a-button type="primary" @click="showModal">新建</a-button>
      </div>
    </div>
    <a-table :columns="columns" :data-source="data" class="components-table-demo-nested">
      <a slot="operation" class="fun" :slot-scope="text" @click="PopupshowModal">新建扣分</a>
      <a slot="operation" class="fun" :slot-scope="text">修改</a>
      <a slot="operation" class="fun" :slot-scope="text">删除</a>
      <a-table
        slot="expandedRowRender"
        :slot-scope="text"
        :columns="innerColumns"
        :data-source="innerData"
        :pagination="false"
      >
        <!-- <span slot="status" slot-scope="text"> <a-badge status="success" />Finished </span> -->
        <span slot="operation" :slot-scope="text" class="table-operation">
          <a class="fun">修改</a>
          <a class="fun">删除</a>
        </span>
      </a-table>
    </a-table>
    <a-modal v-model="detailShow" title="新建扣分" on-ok="handleOk" :bodyStyle="{ height: '320px' }" :width="745">
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleOk" :loading="loading"> 保存 </a-button>
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
      </template>
      <div style="padding-top:50px" v-for="item in index" :key="item">
        <div style="display: flex; align-items: center">
          <div style="display: flex; align-items: center; justify-content: center">
            <span style="margin-right: 5px; width: 110px; text-align: right; display: block;font-weight:bold;">扣分项：</span>
            <a-input placeholder="请输入扣分项" style="width: 60%"></a-input>
          </div>
          <div style="display: flex; align-items: center; justify-content: center">
            <span style="margin-right: 5px; width: 110px; text-align: right; display: block;font-weight:bold;">扣分分值：</span>
            <a-input placeholder="请输入分值" style="width: 60%"></a-input>
          </div>
          <div class="addRemove">
              <a-icon class="icon" type="plus-circle" @click="addProject" />
              <a-icon class="icon" type="minus-circle"  v-if="item!=1" @click="reduceProject"/>
            </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import component from '@/component/*.vue'
const columns = [
  { title: '序号', dataIndex: 'name', key: 'name' },
  { title: '标准要求', dataIndex: 'platform', key: 'platform' },
  { title: '所属检查内容', dataIndex: 'version', key: 'version' },
  { title: '所属检查项目', dataIndex: 'upgradeNum', key: 'upgradeNum' },
  { title: '更新时间', dataIndex: 'creator', key: 'creator' },
  // { title: '', dataIndex: 'createdAt', key: 'createdAt' },
  { title: '操作', key: 'operation', scopedSlots: { customRender: 'operation' } },
]
const data = []
for (let i = 0; i < 3; ++i) {
  data.push({
    key: i,
    name: '1',
    platform: '要求一',
    version: '内容一',
    upgradeNum: '项目一',
    creator: '2017-10-31  23:12:00',
    // createdAt: '2014-12-24 23:12:00',
  })
}

const innerColumns = [
  { title: '选项', dataIndex: 'date', key: 'date' },
  { title: '扣分', dataIndex: 'name', key: 'name' },
  // { title: 'Status', key: 'state', scopedSlots: { customRender: 'status' } },
  // { title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum' },
  {
    title: '',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

const innerData = []
for (let i = 0; i < 3; ++i) {
  innerData.push({
    key: i,
    date: '要求一',
    name: '0',
    upgradeNum: 'Upgraded: 56',
  })
}
export default {
  data() {
    return {
      data,
      columns,
      innerColumns,
      innerData,
      detailShow: false,
      loading:false,
      index :1
    }
  },
  props: {
    showModals: {
      type: Function,
      default: null,
    },
  },
  components: {},
  computed: {},
  filters: {},
  methods: {
    showModal() {
      this.showModals()
    },
    addProject(){
      this.index++
    },

    // 弹出层
    PopupshowModal() {
      this.detailShow = true
    },
    handleOk(e) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.detailShow = false
      }, 3000)
    },
    handleCancel(e) {
      this.detailShow = false
    },
    handleChange(e){
      console.log(e)
    }
  },
  created() {},
  mounted() {},
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.right-top {
  margin-bottom: 53px;
  box-sizing: border-box;
  padding: 42px 30px 0 46px;
}
.search {
  display: flex;
  align-content: center;
}
.newBuild {
  display: flex;
  justify-content: flex-end;
}
.fun {
  margin-right: 10px;
}
.addRemove {
  width: 100px;
  justify-content: space-around;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .icon{
    font-size: 20px;
  }
}
</style>
